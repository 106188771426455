import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookingsCalendar } from "../../../redux/slice/bookingSlice";

const localizer = momentLocalizer(moment);
export default function CalendarPage() {
  const { calendarData } = useSelector((state) => state.booking);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [modalState, setModalState] = useState(false);

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event); // Store selected event data
    setModalState(true); // Show modal
  };

  // Modal component to display event details
  const Modal = () => {
    if (!selectedEvent) return null; // Don't render if no event is selected

    return (
      <div className={`${modalState ? "modal" : "hide"}`} onClick={() => setModalState(false)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <span className="close" onClick={() => setModalState(false)}>&times;</span>
          <h2>Booking Details</h2>
          <p><strong>Expert Name:</strong> {selectedEvent.title}</p>
          <p><strong>Status:</strong> {selectedEvent.status}</p>
          <p><strong>Start Time:</strong> {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm a")}</p>
          <p><strong>End Time:</strong> {moment(selectedEvent.end).format("MMMM Do YYYY, h:mm a")}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getAllBookingsCalendar());
  }, []);

  useEffect(() => {
    setData(calendarData);
  }, [calendarData]);

  const eventStyleGetter = (event) => {
    let style = {};

    switch (event.status) {
      case "pending":
        style = {
          backgroundColor: "rgba(136, 230, 247, 0.5)",
          color: "#05879e",
        };
        break;
      case "cancel":
        style = {
          backgroundColor: "rgba(251, 175, 190, .5)",
          color: "#b3092b",
        };
        break;
      case "completed":
        style = {
          backgroundColor: "rgba(147, 231, 195, 0.5)",
          color: "#1a8a59",
        };
        break;
      default:
        break;
    }

    return {
      style,
    };
  };

  const dummyEvents = calendarData.map((booking) => {
    const startDateTime = moment(
      `${booking?.date} ${booking?.time[0]}`,
      "YYYY-MM-DD HH:mm"
    );
    const endDateTime = startDateTime.clone().add(booking?.duration, "minutes");

    return {
      id: booking?.id,
      title: booking?.expert?.fullName,
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      status: booking?.status,
    };
  });

  return (
    <div>
      <div className="statusShow">
        <h6 className="cancelStatus">Cancel</h6>
        <h6 className="pendingStatus">Pending</h6>
        <h6 className="completedStatus">Completed</h6>
      </div>
      {selectedEvent && <Modal />}
      {dummyEvents?.length > 0 && (
        <Calendar
          localizer={localizer}
          views={["month", "week", "day"]}
          events={dummyEvents}
          style={{ height: 600 }}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleSelectedEvent} // Handle event click
        />
      )}
    </div>
  );
}
