import axios from "axios";

import { DangerRight } from "./toastServices";
import { baseURL, secretKey } from "../../util/config";
import { navigate } from "react-big-calendar/lib/utils/constants";

const getTokenData = () => localStorage.getItem("token");

export const apiInstance = axios.create({
  baseURL: baseURL,
});

const cancelTokenSource = axios.CancelToken.source();
// const token = localStorage.getItem("token");
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`; 
apiInstance.defaults.headers.common["key"] = secretKey;

apiInstance.interceptors.request.use(
  function (config) {
    config.cancelToken = cancelTokenSource.token;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    // console.log(error?.response?.data);
    if (
      error?.response?.data?.code === "E_USER_NOT_FOUND" ||
      error?.response?.data?.code === "E_UNAUTHORIZED"
    ) {
      DangerRight('Need to login first');
      localStorage.clear();
      window.location.reload(false);
      navigate('/login');
    }
    if (!error?.response?.data?.message && !error?.response?.data?.error) {
      console.log("Error+++++:", error);
      DangerRight("Something went Wrong!");
    }

    if (typeof error?.response?.data?.message === "string") {
      DangerRight(error.response.data.message);
    }
    if (Array.isArray(error?.response?.data?.message)) {
        error.response.data.message.forEach((msg) => {
            DangerRight(msg);
        });
    } else if (typeof error?.response?.data?.message === "object") {
        Object.values(error.response.data.message).forEach((msgArray) => {
            msgArray.forEach((msg) => {
                DangerRight(msg);
            });
        });
    }
    return Promise.reject(error);
  }
);

const handleErrors = async (response) => {
  if (!response.ok) {
    
    const data = await response.json();
    
    if (data.code === "E_USER_NOT_FOUND" || data.code === "E_UNAUTHORIZED") {
      DangerRight('Need to login first');
      localStorage.clear();
      navigate('/login');
    }
    if (data.message instanceof Array) {
      data.message.forEach((msg) => DangerRight("error", msg));
    } else if (data.message) {
      DangerRight("error", data.message);
    } else {
      DangerRight("error", "Unexpected error occurred.");
    }
    // console.log(data?.message);
    

    return Promise.reject(data);
  }

  return response.json();
};

const getHeaders = () => ({
  key: secretKey,
  Authorization: `Bearer ${getTokenData()}`,
  "Content-Type": "application/json",
  "Accept":"application/json",
});

export const apiInstanceFetch = {
  baseURL: `${baseURL}`,
  get: (url) =>
    fetch(`${baseURL}${url}`, { method: "GET", headers: getHeaders() }).then(
      handleErrors
    ),

  post: (url, data) =>
    fetch(`${baseURL}${url}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    }).then(handleErrors),

  patch: (url, data) =>
    fetch(`${baseURL}${url}`, {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify(data),
    }).then(handleErrors),

  put: (url, data) =>
    fetch(`${baseURL}${url}`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data),
    }).then(handleErrors),

  delete: (url) =>
    fetch(`${baseURL}${url}`, {
      method: "DELETE",
      headers: getHeaders(),
    }).then(handleErrors),
};